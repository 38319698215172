import {NetboxStatusEvent} from '@/netbox/constants/events';
import {NetboxStatus} from '@/netbox/constants/statuses';
import { convertToNetboxURL, shouldConvertToNetbox } from '@/netbox/helpers/netbox';

function newRequest(request) {
  return new Request(convertToNetboxURL(request.url), {
    method: request.method,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    redirect: request.redirect,
    integrity: request.integrity,
    // headers: request.headers, // disable on ios
    // credentials: request.credentials, // disable on ios
    // cache: request.cache, // disable on ios
  });
}

let isNetBoxActive = false;
// eslint-disable-next-line no-unused-vars
let cacheStorage = null;

self.addEventListener('install', () => {
  // The following causes a newly installed service worker to progress into the activating state, regardless of whether there is already an active service worker.
  self.skipWaiting();
});

self.addEventListener('activate', (event) => {
  event.waitUntil(clients.claim().then(() => {
    clients.matchAll().then((clients) => {
      clients.forEach((client) => {
        client.postMessage({ code: NetboxStatusEvent.Init });
        client.postMessage({ code: NetboxStatusEvent.Get });
      });
    });
  }));
});

self.addEventListener('fetch', (event) => {
  if (isNetBoxActive && shouldConvertToNetbox(event.request.url)) {
    event.respondWith(fetch(newRequest(event.request)).then(response => response));

    // Below implementation of cache
    // Currently we do not use it, but it might be helpful to keep in case we need it later
    //
    // if (cacheStorage) {
    //   event.respondWith(
    //     cacheStorage.match(event.request).then((response) => {
    //       if (response && response.ok) {
    //         return response;
    //       }
    //
    //       return fetch(newRequest(event.request)).then((response) => {
    //         if (response.ok) {
    //           cacheStorage.put(event.request, response.clone());
    //         }
    //
    //         return response;
    //       });
    //     }),
    //   );
    // }
  }
});

self.addEventListener('message', (event) => {
  if (event.data.code !== NetboxStatusEvent.Send) {
    return;
  }

  isNetBoxActive = event.data.status === NetboxStatus.Active;
  caches.open('cutwise').then(cache => cacheStorage = cache);
});
