import { jsx as _jsx } from "react/jsx-runtime";
import { isValidElement } from 'react';
import { toast } from 'react-toastify';
import { isString } from 'lodash';
import { logger } from '@cutwise/utils';
import { ToastNotification } from '@/common/components/ToastNotification/ToastNotification';
// todo: https://github.com/fkhadra/react-toastify/issues/402
export default class Notifier {
    static idToInnerToastId = {};
    static success(message, { delay = 5000, toastId } = {}) {
        Notifier.sendNotification(toast.success, message, delay, toastId ?? undefined);
    }
    static warn(message, { delay = 5000, toastId } = {}) {
        Notifier.sendNotification(toast.warn, message, delay, toastId ?? undefined);
    }
    static error(message, params = {}) {
        if (isValidElement(message) || isString(message)) {
            Notifier.sendErrorNotification(message, params);
        }
        else if (message instanceof Error) {
            logger.error(message);
            Notifier.sendErrorNotification(message.message, params);
        }
        else if (message && message.message) {
            Notifier.sendErrorNotification(message.message, params);
        }
        // if message object do not contain message, do nothing
    }
    static sendErrorNotification(message, { delay = 0, toastId } = {}) {
        if (!message) {
            return;
        }
        Notifier.sendNotification(toast.error, message, delay, toastId ?? undefined);
    }
    static sendNotification(sender, message, delay = 0, toastId) {
        const options = {
            autoClose: delay > 0 ? delay : false,
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            toastId,
        };
        const content = _jsx(ToastNotification, { message: message });
        if (toastId && toast.isActive(Notifier.idToInnerToastId[toastId])) {
            toast.update(Notifier.idToInnerToastId[toastId], {
                ...options,
                render: content,
            });
            return;
        }
        const innerToastId = sender(content, options);
        if (toastId) {
            Notifier.idToInnerToastId[toastId] = innerToastId;
        }
    }
    static dismissNotification(toastId) {
        if (!Notifier.idToInnerToastId[toastId]) {
            return;
        }
        toast.dismiss(Notifier.idToInnerToastId[toastId]);
    }
    static dismissAll() {
        toast.dismiss();
    }
}
