/*
 * IMPORTANT NOTICE:
 *
 * Use next options for local development:
 * Variant 1 - use Docker and production build:
 *   1. Be sure your environment installed: https://octonus-teams.com/wiki/display/CUT/Fresh+Cutwise+local+environment+installation
 *   2. Run "npm run build:prod"
 *   3. Open website via link https://cutwise.local/
 *   4. Go to "Dev. Console -> Application -> Service workers" and unregister worker with source "sw.js"
 *   5. Reload page
 *
 * Variant 2 - use webpack-dev-server:
 *   1. In webpack config disable option "webpackConfig.devtool" (you can simply comment these lines)
 *   2. In webpack config set option "webpackConfig.optimization.runtimeChunk" to "false"
 *   3. Run "npm run serve"
 *   4. Open website via link https://cutwise.local:9009/ (or another address if you have changed host and port for webpack-dev-server)
 *   5. Go to "Dev. Console -> Application -> Service workers" and unregister worker with source "sw.js"
 *   6. Reload page
 *
 * Keep in mind: this variant doesn't work with office netbox.
 *
 * When you are done with code - revert changes in webpack config!!!
 * */

import {NetboxStatusEvent} from '@/netbox/constants/events';
import {NetboxStatus} from '@/netbox/constants/statuses';
import { convertToNetboxURL, shouldConvertToNetbox } from '@/netbox/helpers/netbox';

function newRequest(request) {
  return new Request(convertToNetboxURL(request.url), {
    method: request.method,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    redirect: request.redirect,
    integrity: request.integrity,
    // headers: request.headers, // disable on ios
    // credentials: request.credentials, // disable on ios
    // cache: request.cache, // disable on ios
  });
}

let isNetBoxActive = false;
// eslint-disable-next-line no-unused-vars
let cacheStorage = null;

self.addEventListener('install', () => {
  console.log('Service Worker installing...');

  // The following causes a newly installed service worker to progress into the activating state, regardless of whether there is already an active service worker.
  self.skipWaiting();
});

self.addEventListener('activate', (event) => {
  console.log('Service Worker activating...');

  event.waitUntil(clients.claim().then(() => {
    clients.matchAll().then((clients) => {
      clients.forEach((client) => {
        client.postMessage({ code: NetboxStatusEvent.Init });
        client.postMessage({ code: NetboxStatusEvent.Get });
      });
    });
  }));
});

self.addEventListener('fetch', (event) => {
  if (isNetBoxActive && shouldConvertToNetbox(event.request.url)) {
    event.respondWith(fetch(newRequest(event.request)).then(response => response));

    // Below implementation of cache
    // Currently we do not use it, but it might be helpful to keep in case we need it later
    //
    // if (cacheStorage) {
    //   event.respondWith(
    //     cacheStorage.match(event.request).then((response) => {
    //       if (response && response.ok) {
    //         return response;
    //       }
    //
    //       return fetch(newRequest(event.request)).then((response) => {
    //         if (response.ok) {
    //           cacheStorage.put(event.request, response.clone());
    //         }
    //
    //         return response;
    //       });
    //     }),
    //   );
    // }
  }
});

self.addEventListener('message', (event) => {
  if (event.data.code !== NetboxStatusEvent.Send) {
    return;
  }

  isNetBoxActive = event.data.status === NetboxStatus.Active;
  caches.open('cutwise').then(cache => cacheStorage = cache);
});
