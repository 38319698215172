import { generateSearchQuery, parseSearchQuery, queryAPI, } from '@cutwise/utils';
import { addPrefixToHost } from '@/common/helpers/addPrefixToHost';
import { NETBOX_ORIGIN } from '@/netbox/constants/network';
export default class URLBuilder {
    static params() {
        return { ...queryAPI.params };
    }
    static path() {
        return `${window.location.pathname}${queryAPI.query}`;
    }
    static build(base, path, params) {
        let pathValue = path;
        if (pathValue.startsWith('/')) {
            pathValue = pathValue.slice(1, pathValue.length);
        }
        return `${base}/${pathValue}${URLBuilder.buildQueryString(params)}`;
    }
    static buildUrl(path, params) {
        let base = window.location.origin;
        if (!base.includes(NETBOX_ORIGIN)) {
            base = addPrefixToHost('api');
        }
        return URLBuilder.build(base, path, params);
    }
    static buildQueryString(params, options = {}) {
        return generateSearchQuery(params, { encode: true, ...options });
    }
    static parseQueryString(search, options = {}) {
        return parseSearchQuery(decodeURIComponent(search), options);
    }
    static replace(path) {
        window.history.replaceState({}, '', path);
    }
    static toAbsoluteUrl(path) {
        return `${window.location.origin}${path}`;
    }
}
