export const HOME_PAGE = '/';
export const AUTH_PAGE = '/auth';
export const LOGIN_REDIRECT_PAGE = '/userlogin';
export const CATALOG_DIAMONDS_COLORLESS_PAGE = '/catalog/diamond-colorless';
export const CATALOG_DIAMONDS_COLORLESS_EDIT_PAGE = `${CATALOG_DIAMONDS_COLORLESS_PAGE}/edit`;
export const CATALOG_DIAMONDS_FANCY_PAGE = '/catalog/diamond-fancy';
export const CATALOG_DIAMONDS_FANCY_EDIT_PAGE = `${CATALOG_DIAMONDS_FANCY_PAGE}/edit`;
export const CATALOG_ROUGH_PAGE = '/catalog/rough';
export const CATALOG_ROUGH_EDIT_PAGE = `${CATALOG_ROUGH_PAGE}/edit`;
export const CATALOG_JEWELRY_PAGE = '/catalog/jewelry';
export const CATALOG_JEWELRY_EDIT_PAGE = `${CATALOG_JEWELRY_PAGE}/edit`;
export const CATALOG_LGD_COLORLESS_PAGE = '/catalog/lgd-colorless';
export const CATALOG_LGD_COLORLESS_EDIT_PAGE = `${CATALOG_LGD_COLORLESS_PAGE}/edit`;
export const CATALOG_LGD_FANCY_PAGE = '/catalog/lgd-fancy';
export const CATALOG_LGD_FANCY_EDIT_PAGE = `${CATALOG_LGD_FANCY_PAGE}/edit`;
export const CATALOG_LGD_ROUGH_PAGE = '/catalog/lgd-rough';
export const CATALOG_LGD_ROUGH_EDIT_PAGE = `${CATALOG_LGD_ROUGH_PAGE}/edit`;
export const COMPARE_BY_PRODUCT_TYPE_PAGE = '/compare/:productType';
export const COLLECTIONS_PAGE = '/collections';
export const PRESENTATION_COLLECTIONS_PAGE = '/IGC2023';
export const SINGLE_COLLECTION_PAGE = '/~:collectionId';
export const SWEET_LINE_PAGE = '/sweetline/:collectionId';
export const SINGLE_COLLECTION_EDIT_PAGE = '/~:collectionId/edit';
export const SURVEY_PAGE = '/survey';
export const SURVEY_RESULT_PAGE = '/survey-result';
export const PROJECTS_PAGE = '/projects';
export const SHARED_WITH_ME_PROJECTS_PAGE = '/shared-with-me-projects';
export const CARBON_VIEWER_PAGE = '/carbon-viewer';
export const TASKS_PAGE = '/tasks';
export const SPECTRUM_PAGE = '/spectrum';
export const STEREO_PLAYER_PAGE = '/stereo-player';
export const DRIVE_PAGE = '/drive/:section?/:selectedId?';
export const MULTI_PROJECTS_PAGE = '/multi-projects';
export const MULTI_PROJECT_HISTORY_PAGE = '/multi-projects/:id/history';
export const DIAMOND_DETAILS_PAGE = '/diamond/:b2bSid';
export const DIAMOND_DETAILS_EDIT_PAGE = '/diamond/edit/:b2bSid';
export const ROUGH_DETAILS_PAGE = '/rough/:b2bSid';
export const ROUGH_EDIT_DETAILS_PAGE = '/rough/edit/:b2bSid';
export const JEWELRY_DETAILS_PAGE = '/jewelry/:b2bSid';
export const JEWELRY_EDIT_DETAILS_PAGE = '/jewelry/edit/:b2bSid';
export const SOLUTIONS_PAGE = '/projects/:projectId/solutions';
export const UI_KIT_PAGE = '/uikit';
export const UPLOAD_IMAGE_PAGE = '/upload';
export const OLD_GRADING_REDIRECT_PAGE = '/grading/custom/color-1?id[]=49192&id[]=49193&id[]=48791&id[]=48794&id[]=48820&id[]=48793&id[]=48821&sp=75';
